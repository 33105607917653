/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-04-03",
    versionChannel: "nightly",
    buildDate: "2023-04-03T00:21:21.992Z",
    commitHash: "9441182b7d70d334ea6a7921f9b5541246a5a447",
};
